<template>
  <div class="signup-province-wrap">
    <div class="box plain-shadow-on-desktop">
      <div class="page-title has-text-centered">Your Address</div>
      <ValidationObserver ref="signupForm" v-slot="{ valid }" class="is-inline-block signup-form-component">
        <ValidationProvider name="country" rules="required" v-slot="{ errors, passed, failed }" class="is-inline-block countryOrProvince">
          <b-field :type="classForField({ passed,failed })" :message="errors[0]">
            <b-select name="country" placeholder="Country" v-model="country" @input="resetStateOrProvince" class="is-medium-height is-blue country">
              <option
                v-for="option in Countries"
                :value="option.code"
                :key="option.code"
              >{{ option.name }}</option>
            </b-select>
          </b-field>
        </ValidationProvider>
        <ValidationProvider name="stateOrProvince" rules="required" v-slot="{ errors, passed, failed }" class="is-inline-block countryOrProvince">
          <b-field :type="classForField({ passed, failed })" :message="errors[0]">
            <b-select
              name="stateOrProvince"
              :placeholder="stateOrProvincePlaceholder"
              v-model="stateOrProvince"
              :key="country === 'United States' ? 'stately-input' : 'province-input'"
              class="is-medium-height is-blue stateOrProvince"
            >
              <option
                v-for="option in (country === 'United States' ? UsaStates : CanadaProvinces)"
                :value="option.code"
                :key="option.code"
              >{{ option.name }}</option>
            </b-select>
          </b-field>
        </ValidationProvider>
        <div class="has-text-centered">
          <b-checkbox v-model="subscribed" class="subscribed" type="is-blue">Sign up for newsletter</b-checkbox>
        </div>
        <PromiseLoadingButton
          :action="updateAccount"
          :disabled="!valid || submittingSignup"
          :hasBoxShadow="false"
          class="button has-background-primary is-medium-height is-fullwidth is-rounded"
          data-cy="sign-up-btn"
        >Continue</PromiseLoadingButton>
      </ValidationObserver>
      <div class="has-text-centered terms">By clicking this button, you agree with our
        <a href="https://addyinvest.com/terms-of-use/" class="has-text-primary" target="_blank">Terms of Use</a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import PromiseLoadingButton from '@components/button/promise-loading-button.vue'
import CanadaProvinces from '@utils/data/canada_provinces'
import UsaStates from '@utils/data/usa_states'
import Countries from '@utils/data/countries'
import addyAvailableRegions from '@utils/data/addy_available_regions'
import formMixins from '@utils/mixins/form-mixins.js'
import { initializePendo } from '@utils/common-methods/pendo'
import { setCountry } from '@api/signup'

export default {
  mixins: [formMixins],
  data() {
    return {
      email: undefined,
      country: undefined,
      stateOrProvince: undefined,
      subscribed: false,
      Countries,
      UsaStates,
      CanadaProvinces,
      addyAvailableRegions,
      submittingSignup: false,
    }
  },
  components: {
    PromiseLoadingButton,
  },
  mounted() {
    const jwt = localStorage.getItem('access_token')
    if (jwt) {
      const decoded = JSON.parse(atob(jwt.split('.')[1]))
      this.email = decoded['https://addy.com/email']
    }
  },
  created() {
    this.mixpanel.track('rarv76f7')
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel']),
    stateOrProvincePlaceholder() {
      return this.country === 'United States' ? 'State' : 'Province'
    },
  },
  methods: {
    resetStateOrProvince() {
      this.stateOrProvince = null
    },
    updateAccount() {
      this.submittingSignup = true
      this.$refs.signupForm.validate().then((success) => {
        if (!success) {
          this.submittingSignup = false
          return
        }

        const addyHandle = this.$route.query.addyHandle
        setCountry({
          investor: {
            country: this.country,
            state_or_province: this.stateOrProvince,
            subscribed: this.subscribed,
            referrer_handle: addyHandle ? decodeURIComponent(addyHandle) : null,
          }
        }).then((data) => {
          const { id, email } = data
          initializePendo(id, email)
          this.setUserIdentityWithMixpanel()
          this.storeUserInfo(data)
          this.submittingSignup = false

          let url = ''
          if (this.addyAvailableRegions.includes(this.stateOrProvince)) {
            this.mixpanel.track('toz8ryr7')
            url = `/signup/profile/1`
          } else {
            this.mixpanel.track('xpq5ai7g')
            url = `/signup/regionNotAvailable?email=${encodeURIComponent(email)}`
          }
          this.$router.push(url)
        })
      })
    },
    storeUserInfo(investor) {
      localStorage.setItem('investor', JSON.stringify(investor))
    },
    setUserIdentityWithMixpanel() {
      this.mixpanel.identify(this.email)
      this.$store.commit('mixpanel/setIdentity', true)
      this.mixpanel.people.set({
        '$email': this.email,
        'Sign up date': new Date(),
        'USER_ID': this.email
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.signup-form-component {
  width: 100%;
  .email-field,
  .password-field,
  .confirmed-password-field {
    margin-bottom: 8px;
  }
  .countryOrProvince {
    width: calc((100% - 8px));
    margin: 4px 0;
  }
  .subscribed {
    font-size: 16px;
    margin: 21px 0;
  }
}
.signup-province-wrap {
  padding: 0 25px 60px;
  > .box {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 25px;
    padding-bottom: 34px;
    margin-bottom: 29px;
    box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.15);
    .page-title {
      font-size: 32px;
      font-weight: 600;
      line-height: 38px;
      margin-bottom: 15px;
    }
    .terms {
      font-size: 14px;
      color: #939598;
      margin-top: 14px;
      padding: 0 30px;
      line-height: 17px;
      > a {
        font-weight: 600;
      }
    }
  }
  .to-login {
    font-size: 16px;
    line-height: 19px;
    color: #939598;
    padding-bottom: 66px;
    > span {
      font-weight: 600;
      font-size: 16px;
    }
  }
}
</style>
<style scoped>
.countryOrProvince >>> .select {
  display: inline-block;
  width: 100%;
}
.countryOrProvince >>> .select select {
  width: 100%;
}
.checkbox.subscribed >>> .check {
  width: 22px!important;
  height: 22px!important;
}
</style>
