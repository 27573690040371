var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"signup-province-wrap"},[_c('div',{staticClass:"box plain-shadow-on-desktop"},[_c('div',{staticClass:"page-title has-text-centered"},[_vm._v("Your Address")]),_c('ValidationObserver',{ref:"signupForm",staticClass:"is-inline-block signup-form-component",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('ValidationProvider',{staticClass:"is-inline-block countryOrProvince",attrs:{"name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
var failed = ref.failed;
return [_c('b-field',{attrs:{"type":_vm.classForField({ passed: passed,failed: failed }),"message":errors[0]}},[_c('b-select',{staticClass:"is-medium-height is-blue country",attrs:{"name":"country","placeholder":"Country"},on:{"input":_vm.resetStateOrProvince},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}},_vm._l((_vm.Countries),function(option){return _c('option',{key:option.code,domProps:{"value":option.code}},[_vm._v(_vm._s(option.name))])}),0)],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"is-inline-block countryOrProvince",attrs:{"name":"stateOrProvince","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
var failed = ref.failed;
return [_c('b-field',{attrs:{"type":_vm.classForField({ passed: passed, failed: failed }),"message":errors[0]}},[_c('b-select',{key:_vm.country === 'United States' ? 'stately-input' : 'province-input',staticClass:"is-medium-height is-blue stateOrProvince",attrs:{"name":"stateOrProvince","placeholder":_vm.stateOrProvincePlaceholder},model:{value:(_vm.stateOrProvince),callback:function ($$v) {_vm.stateOrProvince=$$v},expression:"stateOrProvince"}},_vm._l(((_vm.country === 'United States' ? _vm.UsaStates : _vm.CanadaProvinces)),function(option){return _c('option',{key:option.code,domProps:{"value":option.code}},[_vm._v(_vm._s(option.name))])}),0)],1)]}}],null,true)}),_c('div',{staticClass:"has-text-centered"},[_c('b-checkbox',{staticClass:"subscribed",attrs:{"type":"is-blue"},model:{value:(_vm.subscribed),callback:function ($$v) {_vm.subscribed=$$v},expression:"subscribed"}},[_vm._v("Sign up for newsletter")])],1),_c('PromiseLoadingButton',{staticClass:"button has-background-primary is-medium-height is-fullwidth is-rounded",attrs:{"action":_vm.updateAccount,"disabled":!valid || _vm.submittingSignup,"hasBoxShadow":false,"data-cy":"sign-up-btn"}},[_vm._v("Continue")])]}}])}),_vm._m(0)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"has-text-centered terms"},[_vm._v("By clicking this button, you agree with our "),_c('a',{staticClass:"has-text-primary",attrs:{"href":"https://addyinvest.com/terms-of-use/","target":"_blank"}},[_vm._v("Terms of Use")])])}]

export { render, staticRenderFns }